//**************************************************************** */
// Imports
//**************************************************************** */

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import lili from './assets/lili.jpeg';

const About = () => {
  return (
    <HomeContainer>
      <Hero>
          <Lili src={lili}></Lili>
        <Name>Lily Grisold</Name>

        <p>Meet Lily, founder of li.li. She is passionate about API integration
          in MERN stack projects and the dissemination of all types of data.
          In her spare time, she enjoys bike riding and spearfishing off the
          coast of Forster, N.S.W, where she currently resides with her parents
          and their labrador golden retreiver. Previously residing in Montreal
          for the better part of a decade, she also has a passion for all things
          musical.
        </p>
      </Hero>
      <Section>
        <p>Take a look at some of our <StyledLink to="/projects">projects</StyledLink></p>
      </Section>
    </HomeContainer>
  );
};

export default About;

const HomeContainer = styled.div`
  .container {
    padding: 2rem 1rem;

    @media (min-width: 768px) {
      padding: 3rem 2rem;
    }

    @media (min-width: 1024px) {
      padding: 4rem 3rem;
    }
  }
`;

const Hero = styled.section`
  text-align: center;
  padding: 2rem;
  color: #fff;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
  }
`;

const Section = styled.section`
  margin: 2rem 0;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }
`;

const StyledLink = styled(Link)`
  color: var(--color-accent);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const Lili = styled.img`
  width: 20rem;
`;

//**************************************************************** */
// Styled-Components
//**************************************************************** */

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-secondary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
const Name = styled.div`

  font-size: 30px;
  margin: 1rem;
`;
const Description = styled.p`
    width: 450px;
    font-size: 20px;
`;
const FlexRow = styled.div`
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: right;
    align-items: center;
    margin: 0 20px 0 0;
`;
const Logo = styled.img`
    width: 6rem;
    margin: 0.5rem 0 0.5rem 1rem;
`;
const ProfileBtn = styled.button`
    color: white;
    background-color: var(--color-secondary);
    border: none;
    margin: 0 3.5rem;
    height: 100%;
    cursor: pointer;
    transition: ease-in-out 200ms;
    &:hover {
        transform: scale(1.2);
        color: var(--color-quarternary);
    }
    &:active{
        transform: scale(.8);
        color: var(--color-primary);
    }
`;
const LogIn = styled.button`
    font-family: var(--font-heading);
    color: white;
    font-size: 100%;
    border: 0.1rem solid white;
    border-radius: 4.6rem;
    width: 4.6rem;
    height: 4.6rem;
    background-color: var(--color-secondary);
    cursor: pointer;
    transition: ease-in 300ms;
    &:hover {
        border-color: var(--color-secondary);
        color:var(--color-secondary);
        background-color: white;
        transform: scale(1.1);
    }
    &:active{
        border-color: var(--color-quarternary);
        color: var(--color-quarternary);
        transition: ease-in 100ms;
        transform: scale(.8);
    }
`;
const LogOut = styled.button`
    font-family: var(--font-heading);
    font-size: 100%;
    border: 0.1rem solid white;
    border-radius: 4.6rem;
    width: 4.6rem;
    height: 4.6rem;
    color: white;
    align-items: center;
    text-align: center;
    background-color: var(--color-secondary);
    cursor: pointer;
    transition: ease-in 300ms;
    &:hover {
        border-color: var(--color-secondary);
        color:var(--color-secondary);
        background-color: white;
        transform: scale(1.1);
    }
    &:active{
        border-color: var(--color-quarternary);
        color: var(--color-quarternary);
        transition: ease-in 100ms;
        transform: scale(.98);
    }
`;