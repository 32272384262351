//**************************************************************** */
// Imports
//**************************************************************** */

import React from 'react';
import styled from 'styled-components';
import btb from './assets/btb.png'
import yota from './assets/yota.png'
import { Link } from 'react-router-dom';
const Projects = () => {
  return (
    <HomeContainer>
      <Hero>
        <p>Here are some of my projects</p>
        <FlexRow>
        <a href= "https://btb-dev-86b75c3bf6d2.herokuapp.com/">
        <BTB src = {btb}></BTB>
        </a>
        <p>A micromobility as a service application that compares bus and 
          bikeshare routes. Currently only works for the city of Montreal but gives
          an idea of my capability to utilise multiple API's to build something
          quite powerful in React.
        </p>
        </FlexRow>
        <FlexRow>
        <a href= "https://yota.space/en">
        <YOTA src = {yota}></YOTA>
        </a>
        <p>Website I designed for a community organisation that I worked with to build a co-working space
          within, alongside DJ practice and dance studios, in an abandoned bank in 
          downtown Montreal.
        </p>
        </FlexRow>
      </Hero>
      <Section>
        <p>Read <StyledLink to="/about">about</StyledLink> us</p>
      </Section>
    </HomeContainer>
  );
};

export default Projects;

const HomeContainer = styled.div`
  .container {
    padding: 2rem 1rem;

    @media (min-width: 768px) {
      padding: 3rem 2rem;
    }

    @media (min-width: 1024px) {
      padding: 4rem 3rem;
    }
  }
`;

const Hero = styled.section`
  text-align: center;
  padding: 2rem;
  color: #fff;

  h1 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
  }
`;
const BTB = styled.img`
  width: 10rem;
  padding: 1rem;
`;
const YOTA = styled.img`
  width: 10rem;
  padding: 1rem;
`;

const Section = styled.section`
  margin: 2rem 0;
  text-align: center;

  h2 {
    margin-bottom: 1rem;
  }

  p {
    font-size: 1rem;
  }
`;
const StyledLink = styled(Link)`
  color: var(--color-accent);
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;


//**************************************************************** */
// Styled-Components
//**************************************************************** */

const Wrapper = styled.div`

    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-secondary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
const Description = styled.p`
    width: 450px;
    font-size: 20px;
`;
const FlexRow = styled.div`
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 20px 0 0;
`;
const Logo = styled.img`
    width: 6rem;
    margin: 0.5rem 0 0.5rem 1rem;
`;
const ProfileBtn = styled.button`
    color: white;
    background-color: var(--color-secondary);
    border: none;
    margin: 0 3.5rem;
    height: 100%;
    cursor: pointer;
    transition: ease-in-out 200ms;
    &:hover {
        transform: scale(1.2);
        color: var(--color-quarternary);
    }
    &:active{
        transform: scale(.8);
        color: var(--color-primary);
    }
`;
const LogIn = styled.button`
    font-family: var(--font-heading);
    color: white;
    font-size: 100%;
    border: 0.1rem solid white;
    border-radius: 4.6rem;
    width: 4.6rem;
    height: 4.6rem;
    background-color: var(--color-secondary);
    cursor: pointer;
    transition: ease-in 300ms;
    &:hover {
        border-color: var(--color-secondary);
        color:var(--color-secondary);
        background-color: white;
        transform: scale(1.1);
    }
    &:active{
        border-color: var(--color-quarternary);
        color: var(--color-quarternary);
        transition: ease-in 100ms;
        transform: scale(.8);
    }
`;
const LogOut = styled.button`
    font-family: var(--font-heading);
    font-size: 100%;
    border: 0.1rem solid white;
    border-radius: 4.6rem;
    width: 4.6rem;
    height: 4.6rem;
    color: white;
    align-items: center;
    text-align: center;
    background-color: var(--color-secondary);
    cursor: pointer;
    transition: ease-in 300ms;
    &:hover {
        border-color: var(--color-secondary);
        color:var(--color-secondary);
        background-color: white;
        transform: scale(1.1);
    }
    &:active{
        border-color: var(--color-quarternary);
        color: var(--color-quarternary);
        transition: ease-in 100ms;
        transform: scale(.98);
    }
`;