//**************************************************************** */
// Imports
//**************************************************************** */

// Import react dependencies
import React, { useContext, useEffect, useState } from 'react';
import styled from "styled-components";
import { NavLink, useNavigate } from "react-router-dom";

// Local dependencies
import logo from "./assets/logo.png";

// Icons
import { FaBars, FaRegUser } from "react-icons/fa";
import { UserContext } from "../UserContext";

// The Header is an element that will sit at the top of 
// all pages, it is defined as a constant here and passed
// to App.
const Header = () => {
    //**************************************************************** */
    // Constants
    //**************************************************************** */

    // Use context to access states initialized in UserContext

    // Define a navigator to allow us to use Navigate to move
    // the user to the desired page without them clicking on 
    // any links
    const navigate = useNavigate();

    // Function that navigates to the specified route (/profile) on click
    const [route, setRoute] = useState("");
    useEffect(() => {
        if (route) {
            navigate(`/${route}`);
        }
    }, [route]);

    const handleClick = (routeName) => {
        setRoute(routeName);
    };

    // State to handle dropdown visibility
    const [dropdownVisible, setDropdownVisible] = useState(false);

    const toggleDropdown = () => {
        setDropdownVisible(!dropdownVisible);
    };

    //**************************************************************** */
    // Render
    //**************************************************************** */

    return (
        <>
            <Wrapper>
                <LogoAndSearch>
                    <NavLink to={"/"}>
                        <Logo src={logo} onClick={() => { handleClick("") }} />
                    </NavLink>
                </LogoAndSearch>
                <FlexRow>
                    <MenuIcon onClick={toggleDropdown}>
                        <FaBars size={30} />
                    </MenuIcon>
                    {dropdownVisible && (
                        <DropdownMenu>
                            <NavLink to="/" onClick={() => { handleClick(""); toggleDropdown(); }}>Home</NavLink>
                            <NavLink to="/about" onClick={() => { handleClick("about"); toggleDropdown(); }}>About</NavLink>
                            <NavLink to="/projects" onClick={() => { handleClick("projects"); toggleDropdown(); }}>Projects</NavLink>
                        </DropdownMenu>
                    )}
                </FlexRow>
            </Wrapper>
        </>
    )
};

// Export the component to be used in App
export default Header;

//**************************************************************** */
// Styled-Components
//**************************************************************** */

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--color-secondary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
`;
const LogoAndSearch = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
const FlexRow = styled.div`
    width: 100%;
    height: inherit;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 20px 0 0;
`;
const Logo = styled.img`
    width: 6rem;
    margin: 0.5rem 0 0.5rem 1rem;
    border: 1px solid white;
`;
const MenuIcon = styled.div`
    cursor: pointer;
    color: white;
`;
const DropdownMenu = styled.div`
    position: absolute;
    top: 60px;
    right: 20px;
    background-color: var(--color-secondary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    border-radius: 8px;
    overflow: hidden;

    a {
        display: block;
        padding: 12px 20px;
        color: white;
        text-decoration: none;
        transition: background-color 0.3s ease-in-out;

        &:hover {
            background-color: var(--color-quarternary);
        }
    }
`;
