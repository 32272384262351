import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import GlobalStyles from './GlobalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import React from 'react';
import About from './components/About';
import Projects from './components/Projects';

function App() {
  return (
    <>
    <GlobalStyles/>
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
